import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Box, Heading } from "@chakra-ui/react";
import { getAllAnexosAllDist } from "../api/get-anexos-all-distribuidores";
import { useAppContext } from "../context/AppContext";
import { getToken } from "../api/get-token-api";

const GraficoAnexos = () => {
  const [anualData, setAnualData] = useState([]);
  const [trimestralData, setTrimestralData] = useState([]);
  const [token, setToken] = useState("");

  const { anoGlobalAtivo, trimestreGlobalAtivo, emailGlobal, passwordGlobal } =
    useAppContext();

  useEffect(() => {
    const formatData = async () => {
      try {
        // Obtendo token
        const tokenData = await getToken(emailGlobal, passwordGlobal);
        setToken(tokenData.access_token);

        // Obtendo dados da API
        const data = await getAllAnexosAllDist(
          anoGlobalAtivo,
          trimestreGlobalAtivo,
          "2",
          tokenData.access_token
        );

        console.log("Dados recebidos:", data);
        console.log("Dados recebidos: anual", data.Anual);
        console.log("Dados recebidos: trimestral", data.Trimestral);

        // Setando os dados formatados para os gráficos
        setAnualData(data.Anual);
        setTrimestralData(data.Trimestral);
      } catch (error) {
        console.error("Erro ao carregar dados do gráfico:", error);
      }
    };

    formatData();
  }, [emailGlobal, passwordGlobal, anoGlobalAtivo, trimestreGlobalAtivo]);

  // Função para garantir que os valores nulos sejam tratados
  const prepareDataForChart = (data) => {
    return data.map((item) => ({
      distribuidor: item.distribuidor,
      anexos: item.anexos || 0, // Usar 0 se anexos for indefinido
      itens: item.itens || 0, // Usar 0 se itens for indefinido
    }));
  };

  const preparedAnualData = prepareDataForChart(anualData);
  const preparedTrimestralData = prepareDataForChart(trimestralData);

  return (
    <Box w="100%" h="100%">
      <Heading as="h3" size="lg" mb={4}>
        Anexos Itens Anuais
      </Heading>
      <Box w="100%" h="800px">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            layout="vertical"
            data={preparedAnualData}
            margin={{
              top: 20,
              right: 30,
              left: 50,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis dataKey="distribuidor" type="category" />
            <Tooltip />
            <Legend />
            <Bar dataKey="anexos" fill="#FFC050" name="Anexos (Anual)" />
            <Bar dataKey="itens" fill="#7a7a7a" name="Itens (Anual)" />
          </BarChart>
        </ResponsiveContainer>
      </Box>

      <Heading as="h3" size="lg" mb={4}>
        Anexos Itens Trimestrais
      </Heading>
      <Box w="100%" h="800px">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            layout="vertical"
            data={preparedTrimestralData}
            margin={{
              top: 20,
              right: 30,
              left: 50,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis dataKey="distribuidor" type="category" />
            <Tooltip />
            <Legend />
            <Bar dataKey="anexos" fill="#FFC050" name="Anexos (Trimestral)" />
            <Bar dataKey="itens" fill="#7a7a7a" name="Itens (Trimestral)" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default GraficoAnexos;
