export const validCredentials = [
  {
    nivelAvaliador: "global",
    profile: "Administrador",
    userPex: "pex.rodrigoval",
    email: "rodrigo.val@raizen.com",
    access: [
      {
        chapter0: "Saúde, Segurança e Meio Ambiente (SSMA)",
        chapter1: "Movimento de Gente Distribuidor (MGD)",
        chapter2: "Tecnologia da Informação",
        chapter3: "Troca Certa",
        chapter4: "Estrutura de Vendas",
        chapter5: "Sistema de Gestão",
        chapter6: "Metodologia de Vendas",
        chapter7: "Trade Marketing",
        chapter8: "Execução de Vendas B2C",
        chapter9: "Execução de Vendas B2B",
        chapter10: "Resultados do Distribuidor",
        name: "Admin",
      },
    ],
  },
  {
    nivelAvaliador: "Consultor de PD",
    userPex: "pex.wesleyjunior",
    profile: "Avaliador",
    email: "wesley.junior@raizen.com",
    access: [
      {
        chapter0: "Movimento de Gente Distribuidor (MGD)",
        chapter1: "Troca Certa",
        chapter2: "Sistema de Gestão",
        chapter3: null,
        name: "Wesley Junior",
      },
    ],
  },
  {
    nivelAvaliador: "global",
    profile: "Administrador",
    userPex: "pex.carolineoliveira",
    email: "caroline.oliveira@raizen.com",
    access: [
      {
        chapter0: "Saúde, Segurança e Meio Ambiente (SSMA)",
        chapter1: "Movimento de Gente Distribuidor (MGD)",
        chapter2: "Tecnologia da Informação",
        chapter3: "Troca Certa",
        chapter4: "Estrutura de Vendas",
        chapter5: "Sistema de Gestão",
        chapter6: "Metodologia de Vendas",
        chapter7: "Trade Marketing",
        chapter8: "Execução de Vendas B2C",
        chapter9: "Execução de Vendas B2B",
        chapter10: "Resultados do Distribuidor",
        name: "Caroline Oliveira",
      },
    ],
  },
  {
    nivelAvaliador: "Consultor Franquia B2C",
    profile: "Avaliador",
    userPex: "pex.cleversonguimaraes",

    email: "cleverson.guimaraes@raizen.com",
    access: [
      {
        chapter0: "Sistema de Gestão",
        chapter1: "Metodologia de Vendas",
        chapter2: "Execução de Vendas B2C",
        chapter3: "Resultados do Distribuidor",
        name: "Cleverson Guimaraes",
      },
    ],
  },
  {
    nivelAvaliador: "Consultor Franquia",
    profile: "Avaliador",
    userPex: "pex.rodrigosilva",

    email: "rodrigo.r.silva@raizen.com",
    access: [
      {
        chapter0: "Metodologia de Vendas",
        chapter1: "Tecnologia da Informação",
        chapter2: "Sistema de Gestão",
        chapter3: null,
        name: "Rodrigo Silva",
      },
    ],
  },
  {
    nivelAvaliador: "Consultor Franquia B2B",
    profile: "Avaliador",
    userPex: "pex.rodrigogodoy",

    email: "rodolfo.godoy@raizen.com",
    access: [
      {
        chapter0: "Troca Certa",
        chapter1: "Metodologia de Vendas",
        chapter2: "Execução de Vendas B2B",
        chapter3: "Resultados do Distribuidor",
        name: "Rodolfo Godoy",
      },
    ],
  },
  {
    nivelAvaliador: "Consultor Franquia B2C",
    profile: "Avaliador",
    userPex: "pex.fernandoamorim",

    email: "fernando.amorim@raizen.com",
    access: [
      {
        chapter0: "Sistema de Gestão",
        chapter1: "Metodologia de Vendas",
        chapter2: "Execução de Vendas B2C",
        chapter3: "Resultados do Distribuidor",
        name: "Fernando Amorim",
      },
    ],
  },
  {
    nivelAvaliador: "Consultor Franquia",
    profile: "Avaliador",
    userPex: "pex.emoraes",

    email: "e.moraes@raizen.com",
    access: [
      {
        chapter0: "Metodologia de Vendas",
        chapter1: "Tecnologia da Informação",
        chapter2: "Sistema de Gestão",
        chapter3: null,
        name: "Eduardo Moraes",
      },
    ],
  },
  {
    nivelAvaliador: "Consultor Franquia",
    profile: "Avaliador",
    userPex: "pex.thomazsantos",

    email: "thomaz.santos@raizen.com",
    access: [
      {
        chapter0: "Metodologia de Vendas",
        chapter1: "Tecnologia da Informação",
        chapter2: "Sistema de Gestão",
        chapter3: null,
        name: "Thomaz Santos",
      },
    ],
  },
  {
    nivelAvaliador: "Consultor Franquia B2C",
    profile: "Avaliador",
    userPex: "pex.ivosobrinho",

    email: "ivo.sobrinho@raizen.com",
    access: [
      {
        chapter0: "Sistema de Gestão",
        chapter1: "Metodologia de Vendas",
        chapter2: "Execução de Vendas B2C",
        chapter3: "Resultados do Distribuidor",
        name: "Ivo Baiao",
      },
    ],
  },
  {
    nivelAvaliador: "Consultor Franquia B2B",
    profile: "Avaliador",
    userPex: "pex.rodrigodoria",

    email: "rodrigo.doria@raizen.com",
    access: [
      {
        chapter0: "Troca Certa",
        chapter1: "Metodologia de Vendas",
        chapter2: "Execução de Vendas B2B",
        chapter3: "Resultados do Distribuidor",
        name: "Rodrigo Doria",
      },
    ],
  },
  {
    nivelAvaliador: "Logistica",
    profile: "Avaliador",
    userPex: "pex.falves",

    email: "f.alves@raizen.com",
    access: [
      {
        chapter0: "Sistema de Gestão",
        chapter1: null,
        chapter2: null,
        chapter3: null,
        name: "Fabiana Alves",
      },
    ],
  },
  {
    nivelAvaliador: "Logistica",
    profile: "Avaliador",
    userPex: "pex.adeoliveirasantos",

    email: "a.deoliveirasantos@raizen.com",
    access: [
      {
        chapter0: "Sistema de Gestão",
        chapter1: null,
        chapter2: null,
        chapter3: null,
        name: "Anderson Santos",
      },
    ],
  },
  {
    nivelAvaliador: "Qualidade",
    profile: "Avaliador",
    userPex: "pex.alineabreu2",

    email: "aline.abreu2@raizen.com",
    access: [
      {
        chapter0: "Troca Certa",
        name: "Aline Abreu",
      },
    ],
  },
  {
    nivelAvaliador: "SSMA",
    profile: "Avaliador",
    userPex: "pex.viviansantos",

    email: "vivian.santos2@raizen.com",
    access: [
      {
        chapter0: "Saúde, Segurança e Meio Ambiente (SSMA)",
        chapter1: "Troca Certa",
        name: "Vivian Santos",
      },
    ],
  },
  {
    nivelAvaliador: "TI",
    profile: "Administrador",
    userPex: "pex.flaviocosta",
    email: "flavio.costa@raizen.com",
    access: [
      {
        chapter0: "Saúde, Segurança e Meio Ambiente (SSMA)",
        chapter1: "Movimento de Gente Distribuidor (MGD)",
        chapter2: "Tecnologia da Informação",
        chapter3: "Troca Certa",
        chapter4: "Estrutura de Vendas",
        chapter5: "Sistema de Gestão",
        chapter6: "Metodologia de Vendas",
        chapter7: "Trade Marketing",
        chapter8: "Execução de Vendas B2C",
        chapter9: "Execução de Vendas B2B",
        chapter10: "Resultados do Distribuidor",
        name: "Flavio Costa",
      },
    ],
  },
  {
    nivelAvaliador: "Trade Mkt",
    profile: "Avaliador",
    userPex: "pex.larissabugada",

    email: "larissa.bugada@raizen.com",
    access: [
      {
        chapter0: "Trade Marketing",
        name: "Larissa Bugada",
      },
    ],
  },
  {
    nivelAvaliador: "Trade Mkt",
    profile: "Avaliador",
    userPex: "pex.stefanysilva",

    email: "stefany.silva@raizen.com",
    access: [
      {
        chapter0: "Trade Marketing",
        name: "Stefany Silva",
      },
    ],
  },
  {
    nivelAvaliador: "Trade Mkt",
    profile: "Avaliador",
    userPex: "pex.larissatrindade",

    email: "larissa.trindade@raizen.com",
    access: [
      {
        chapter0: "Trade Marketing",
        name: "Larissa Trindade",
      },
    ],
  },
  {
    nivelAvaliador: "Trade Mkt",
    profile: "Avaliador",
    userPex: "pex.carolinebassetto",

    email: "caroline.bassetto@raizen.com",
    access: [
      {
        chapter0: "Trade Marketing",
        name: "Caroline Bassetto",
      },
    ],
  },
  {
    nivelAvaliador: "Trade Mkt",
    profile: "Avaliador",
    userPex: "pex.anacarolina",

    email: "anacarolina.goncalves@raizen.com",
    access: [
      {
        chapter0: "Trade Marketing",
        name: "Ana Carolina Goncalves",
      },
    ],
  },
  {
    nivelAvaliador: "Trade Mkt",
    profile: "Avaliador",
    userPex: "pex.mariaczerniewicz",

    email: "maria.czerniewicz@raizen.com",
    access: [
      {
        chapter0: "Trade Marketing",
        name: "Maria Luiza",
      },
    ],
  },
  {
    nivelAvaliador: "global",
    profile: "Administrador",
    userPex: "pex.admin",

    email: "admin@pex.br",
    access: [
      {
        chapter0: "Saúde, Segurança e Meio Ambiente (SSMA)",
        chapter1: "Movimento de Gente Distribuidor (MGD)",
        chapter2: "Tecnologia da Informação",
        chapter3: "Troca Certa",
        chapter4: "Estrutura de Vendas",
        chapter5: "Sistema de Gestão",
        chapter6: "Metodologia de Vendas",
        chapter7: "Trade Marketing",
        chapter8: "Execução de Vendas B2C",
        chapter9: "Execução de Vendas B2B",
        chapter10: "Resultados do Distribuidor",
        name: "Admin",
      },
    ],
  },
  {
    nivelAvaliador: "global",
    profile: "Administrador",
    email: "pex.admin",
    userPex: "pex.admin",

    access: [
      {
        chapter0: "Saúde, Segurança e Meio Ambiente (SSMA)",
        chapter1: "Movimento de Gente Distribuidor (MGD)",
        chapter2: "Tecnologia da Informação",
        chapter3: "Troca Certa",
        chapter4: "Estrutura de Vendas",
        chapter5: "Sistema de Gestão",
        chapter6: "Metodologia de Vendas",
        chapter7: "Trade Marketing",
        chapter8: "Execução de Vendas B2C",
        chapter9: "Execução de Vendas B2B",
        chapter10: "Resultados do Distribuidor",
        name: "Admin",
      },
    ],
  },
  {
    profile: "Distribuidor",
    email: "pex.brazmax",
    access: [],
  },
  {
    profile: "Avaliador",
    email: "avaliador",
    access: [],
    nivelAvaliador: "global",
  },
  {
    nivelAvaliador: "global",
    profile: "Administrador",
    email: "andre@nexusconsultoriams.com.br",
    password: "admin",
    empresa: "nexus",
    access: [
      {
        chapter0: "Saúde, Segurança e Meio Ambiente (SSMA)",
        chapter1: "Movimento de Gente Distribuidor (MGD)",
        chapter2: "Tecnologia da Informação",
        chapter3: "Troca Certa",
        chapter4: "Estrutura de Vendas",
        chapter5: "Sistema de Gestão",
        chapter6: "Metodologia de Vendas",
        chapter7: "Trade Marketing",
        chapter8: "Execução de Vendas B2C",
        chapter9: "Execução de Vendas B2B",
        chapter10: "Resultados do Distribuidor",
        name: "Admin",
      },
    ],
  },
  {
    nivelAvaliador: "global",
    profile: "Administrador",
    email: "jose.mariano@raizen.com",
    password: "admin",
    access: [
      {
        chapter0: "Saúde, Segurança e Meio Ambiente (SSMA)",
        chapter1: "Movimento de Gente Distribuidor (MGD)",
        chapter2: "Tecnologia da Informação",
        chapter3: "Troca Certa",
        chapter4: "Estrutura de Vendas",
        chapter5: "Sistema de Gestão",
        chapter6: "Metodologia de Vendas",
        chapter7: "Trade Marketing",
        chapter8: "Execução de Vendas B2C",
        chapter9: "Execução de Vendas B2B",
        chapter10: "Resultados do Distribuidor",
        name: "José Vitor",
      },
    ],
  },
  {
    nivelAvaliador: "global",
    profile: "Administrador",
    email: "vitoria.okahayashi2@raizen.com",
    password: "admin",
    access: [
      {
        chapter0: "Saúde, Segurança e Meio Ambiente (SSMA)",
        chapter1: "Movimento de Gente Distribuidor (MGD)",
        chapter2: "Tecnologia da Informação",
        chapter3: "Troca Certa",
        chapter4: "Estrutura de Vendas",
        chapter5: "Sistema de Gestão",
        chapter6: "Metodologia de Vendas",
        chapter7: "Trade Marketing",
        chapter8: "Execução de Vendas B2C",
        chapter9: "Execução de Vendas B2B",
        chapter10: "Resultados do Distribuidor",
        name: "Vitória Okahayashi",
      },
    ],
  },
  {
    nivelAvaliador: "global",
    profile: "Administrador",
    email: "ricardo.rocha@raizen.com",
    password: "admin",
    access: [
      {
        chapter0: "Saúde, Segurança e Meio Ambiente (SSMA)",
        chapter1: "Movimento de Gente Distribuidor (MGD)",
        chapter2: "Tecnologia da Informação",
        chapter3: "Troca Certa",
        chapter4: "Estrutura de Vendas",
        chapter5: "Sistema de Gestão",
        chapter6: "Metodologia de Vendas",
        chapter7: "Trade Marketing",
        chapter8: "Execução de Vendas B2C",
        chapter9: "Execução de Vendas B2B",
        chapter10: "Resultados do Distribuidor",
        name: "Ricardo Rocha",
      },
    ],
  },
  {
    nivelAvaliador: "Consultor Franquia B2C",
    profile: "Avaliador",
    userPex: "pex.anadallymiranda",

    email: "anadelly.miranda@raizen.com",
    access: [
      {
        chapter0: "Sistema de Gestão",
        chapter1: "Metodologia de Vendas",
        chapter2: "Execução de Vendas B2C",
        chapter3: "Resultados do Distribuidor",
        name: "Anadelly Miranda",
      },
    ],
  },
  {
    nivelAvaliador: "Consultor Franquia B2C",
    profile: "Avaliador",
    userPex: "pex.rogercarvalho",

    email: "roger.carvalho@raizen.com",
    access: [
      {
        chapter0: "Sistema de Gestão",
        chapter1: "Metodologia de Vendas",
        chapter2: "Execução de Vendas B2C",
        chapter3: "Resultados do Distribuidor",
        name: "Roger Carvalho",
      },
    ],
  },
  {
    nivelAvaliador: "Consultor Franquia B2C",
    profile: "Avaliador",
    userPex: "pex.albertosiqueira",

    email: "alberto.siqueira@raizen.com",
    access: [
      {
        chapter0: "Sistema de Gestão",
        chapter1: "Metodologia de Vendas",
        chapter2: "Execução de Vendas B2C",
        chapter3: "Resultados do Distribuidor",
        name: "Alberto Siqueira",
      },
    ],
  },
  {
    nivelAvaliador: "Consultor Franquia B2C",
    profile: "Avaliador",
    userPex: "pex.daianasilva3",

    email: "daiana.silva3@raizen.com",
    access: [
      {
        chapter0: "Sistema de Gestão",
        chapter1: "Metodologia de Vendas",
        chapter2: "Execução de Vendas B2C",
        chapter3: "Resultados do Distribuidor",
        name: "Daiana Silva",
      },
    ],
  },
  {
    nivelAvaliador: "Consultor Franquia B2B",
    profile: "Avaliador",
    userPex: "pex.manoelafonseca",

    email: "manoela.fonseca@raizen.com",
    access: [
      {
        chapter0: "Troca Certa",
        chapter1: "Metodologia de Vendas",
        chapter2: "Execução de Vendas B2B",
        chapter3: "Resultados do Distribuidor",
        name: "Manoela Fonseca",
      },
    ],
  },
  {
    nivelAvaliador: "Consultor Franquia B2B",
    profile: "Avaliador",
    userPex: "pex.caiogoncalves",

    email: "caio.goncalves@raizen.com",
    access: [
      {
        chapter0: "Troca Certa",
        chapter1: "Metodologia de Vendas",
        chapter2: "Execução de Vendas B2B",
        chapter3: "Resultados do Distribuidor",
        name: "Caio Gonçalves",
      },
    ],
  },
  {
    nivelAvaliador: "Logistica",
    profile: "Avaliador",
    userPex: "pex.tassiacamara",

    email: "tassia.camara@raizen.com",
    access: [
      {
        chapter0: "Sistema de Gestão",
        chapter1: null,
        chapter2: null,
        chapter3: null,
        name: "Tassia Camara",
      },
    ],
  },
  {
    nivelAvaliador: "Trade Mkt",
    profile: "Avaliador",
    userPex: "pex.raqueljesus",

    email: "raquel.jesus@raizen.com",
    access: [
      {
        chapter0: "Trade Marketing",
        name: "Raquel Jesus",
      },
    ],
  },
  {
    nivelAvaliador: "global",
    profile: "Administrador",
    email: "maria.lima7@raizen.com",
    password: "admin",
    access: [
      {
        chapter0: "Saúde, Segurança e Meio Ambiente (SSMA)",
        chapter1: "Movimento de Gente Distribuidor (MGD)",
        chapter2: "Tecnologia da Informação",
        chapter3: "Troca Certa",
        chapter4: "Estrutura de Vendas",
        chapter5: "Sistema de Gestão",
        chapter6: "Metodologia de Vendas",
        chapter7: "Trade Marketing",
        chapter8: "Execução de Vendas B2C",
        chapter9: "Execução de Vendas B2B",
        chapter10: "Resultados do Distribuidor",
        name: "Maria Amelia Lima",
      },
    ],
  },
  {
    nivelAvaliador: "global",
    profile: "Administrador",
    email: "felipe.leocata@raizen.com",
    password: "admin",
    access: [
      {
        chapter0: "Saúde, Segurança e Meio Ambiente (SSMA)",
        chapter1: "Movimento de Gente Distribuidor (MGD)",
        chapter2: "Tecnologia da Informação",
        chapter3: "Troca Certa",
        chapter4: "Estrutura de Vendas",
        chapter5: "Sistema de Gestão",
        chapter6: "Metodologia de Vendas",
        chapter7: "Trade Marketing",
        chapter8: "Execução de Vendas B2C",
        chapter9: "Execução de Vendas B2B",
        chapter10: "Resultados do Distribuidor",
        name: "Felipe Leocata",
      },
    ],
  },
];

export const distCredentials = [
  {
    nome: "DIEGO GOFAÇA",
    email: "marketing@portolub.com.br",
    empresa: "PORTOLUB",
    distribuidor: "MARIA EDUARDA",
    profile: "Distribuidor",
  },
  {
    nome: "MARCELO FELDMANN",
    email: "excelencia@portolub.com.br",
    empresa: "PORTOLUB",
    distribuidor: "Nadja Moretti",
    profile: "Distribuidor",
  },
  // {
  //   nome: "MARIA EDUARDA",
  //   email: "marketing2@portolub.com.br",
  //   empresa: "PORTOLUB",
  //   distribuidor: "Nadja Moretti",
  //   profile: "Distribuidor",
  // },
  {
    nome: "Paola Dorow",
    email: "paola.d@agricopel.com.br",
    empresa: "AGRICOPEL",
    distribuidor: "Jefferson Bearari",
    profile: "Distribuidor",
  },
  {
    nome: "Nadja Moretti",
    email: "nadja.p@agricopel.com.br",
    empresa: "AGRICOPEL",
    distribuidor: "Jefferson Bearari",
    profile: "Distribuidor",
  },
  {
    nome: "Jefferson Bearari",
    email: "jefferson.bearari@lubpar.com.br",
    empresa: "LUBPAR",
    distribuidor: "Luan Falcão",
    profile: "Distribuidor",
  },
  {
    nome: "Luan Falcão",
    email: "luan.falcao@lubpar.com.br",
    empresa: "LUBPAR",
    distribuidor: "Erika Luiza",
    profile: "Distribuidor",
  },
  {
    nome: "Erika Luiza",
    email: "erika.luiza@lubpar.com.br",
    empresa: "LUBPAR",
    distribuidor: "Andrei Gonçalves",
    profile: "Distribuidor",
  },
  {
    nome: "Andrei Gonçalves",
    email: "andrei.goncalves@lubpar.com.br",
    empresa: "LUBPAR",
    distribuidor: "Andrei Gonçalves",
    profile: "Distribuidor",
  },
  {
    nome: "João Guilherme",
    email: "joao.guilherme@nacionallubrificantes.com.br",
    empresa: "NACIONAL",
    distribuidor: "Caroline Heintz",
    profile: "Distribuidor",
  },
  {
    nome: "Caroline Heintz",
    email: "caroline.oliveira@nacionallubrificantes.com.br",
    empresa: "NACIONAL",
    distribuidor: "Wanderson Castiglioni",
    profile: "Distribuidor",
  },
  {
    nome: "Wanderson Castiglioni",
    email: "wanderson@nacionalatacado.com.br",
    empresa: "NACIONAL",
    distribuidor: "Gustavo Araujo Coelho",
    profile: "Distribuidor",
  },
  {
    nome: "Gustavo Araujo Coelho",
    email: "gustavo.coelho@quite.com.br",
    empresa: "QUITE",
    distribuidor: "Natália Santrini",
    profile: "Distribuidor",
  },
  {
    nome: "Natália Santrini",
    email: "natalia@distribuidoraboanova.com.br",
    empresa: "BOA NOVA",
    distribuidor: "Lucas El Fara",
    profile: "Distribuidor",
  },
  {
    nome: "Lucas El Fara",
    email: "lucas@distribuidoraboanova.com.br",
    empresa: "BOA NOVA",
    distribuidor: "Lucilene Versace",
    profile: "Distribuidor",
  },
  {
    nome: "Lucilene Versace",
    email: "financeiro@distribuidoraboanova.com.br",
    empresa: "BOA NOVA",
    distribuidor: "Eduardo Versace",
    profile: "Distribuidor",
  },
  {
    nome: "Eduardo Versace",
    email: "eduardoversace@distribuidoraboanova.com.br",
    empresa: "BOA NOVA",
    distribuidor: "Matheus Torquetti",
    profile: "Distribuidor",
  },
  {
    nome: "Matheus Torquetti",
    email: "matheus.torquetti@decminas.com.br",
    empresa: "DECLUB",
    distribuidor: "Renato Souza",
    profile: "Distribuidor",
  },
  {
    nome: "Renato Souza",
    email: "renato.souza@decminas.com.br",
    empresa: "DECLUB",
    distribuidor: "Jussara Teles",
    profile: "Distribuidor",
  },
  // {
  //   nome: "Jussara Teles",
  //   email: "jussara.teles@declub.com.br",
  //   empresa: "DECLUB",
  //   distribuidor: "LUDMILA ALVES DOS SANTOS",
  //   profile: "Distribuidor",
  // },
  {
    nome: "Karine Aguiar",
    email: "karine.aguiar@decminas.com.br",
    empresa: "DECLUB",
    distribuidor: "Karine Aguiar",
    profile: "Distribuidor",
  },
  {
    nome: "LUDMILA ALVES DOS SANTOS",
    email: "ludmila.alves@formuladistribuidora.com.br",
    empresa: "FORMULA",
    distribuidor: "ITALLO NASCENTE",
    profile: "Distribuidor",
  },
  {
    nome: "ITALLO NASCENTE",
    email: "itallo.nascente@formuladistribuidora.com.br",
    empresa: "FORMULA",
    distribuidor: "Adrielle Barros",
    profile: "Distribuidor",
  },
  {
    nome: "Adrielle Barros",
    email: "adrielle.barros@brazmax.com.br",
    empresa: "BRAZMAX",
    distribuidor: "Anandda Lima",
    profile: "Distribuidor",
  },
  {
    nome: "Anandda Lima",
    email: "anandda.lima@brazmax.com.br",
    empresa: "BRAZMAX",
    distribuidor: "Kevin Silva",
    profile: "Distribuidor",
  },
  // {
  //   nome: "Kevin Silva",
  //   email: "kevin.silva@brazmax.com.br",
  //   empresa: "BRAZMAX",
  //   distribuidor: "Diego Souza",
  //   profile: "Distribuidor",
  // },
  {
    nome: "Everton Santos",
    email: "everton.santos@brazmax.com.br",
    empresa: "BRAZMAX",
    distribuidor: "Diego Souza",
    profile: "Distribuidor",
  },
  {
    nome: "Diego Souza",
    email: "diego.souza@pneubras.com",
    empresa: "PNBLUB",
    distribuidor: "Luciano Behnke",
    profile: "Distribuidor",
  },
  {
    nome: "Luciano Behnke",
    email: "luciano.behnke@pnblub.com",
    empresa: "PNBLUB",
    distribuidor: "Anderson Gama Mendes",
    profile: "Distribuidor",
  },
  {
    nome: "Victor Forte",
    email: "victor.forte@pneubras.com",
    empresa: "PNBLUB",
    distribuidor: "Victor Forte",
    profile: "Distribuidor",
  },
  {
    nome: "Camila Oscar",
    email: "camila.oscar@pnblub.com",
    empresa: "PNBLUB",
    distribuidor: "Camila Oscar",
    profile: "Distribuidor",
  },
  {
    nome: "Anderson Gama Mendes",
    email: "anderson.gama@grupopmz.com.br",
    empresa: "SPM",
    distribuidor: "Ana Carolina Melo Smith",
    profile: "Distribuidor",
  },
  {
    nome: "Ana Carolina Melo Smith",
    email: "ana.smith@grupopmz.com.br",
    empresa: "SPM",
    distribuidor: "Graziane Simone De Souza Lucas",
    profile: "Distribuidor",
  },
  {
    nome: "Graziane Simone De Souza Lucas",
    email: "graziane.lucas@grupopmz.com.br",
    empresa: "SPM",
    distribuidor: "Larissa Souza Pinho",
    profile: "Distribuidor",
  },
  // {
  //   nome: "Larissa Souza Pinho",
  //   email: "larissa.pinho@grupopmz.com.br",
  //   empresa: "SPM",
  //   distribuidor: "CINTIA MARINHO",
  //   profile: "Distribuidor",
  // },
  {
    nome: "CINTIA MARINHO",
    email: "cintia@lubtrol.com.br",
    empresa: "LUBTROL",
    distribuidor: "JULIETA ARAUJO",
    profile: "Distribuidor",
  },
  {
    nome: "JULIETA ARAUJO",
    email: "julieta@lubtrol.com.br",
    empresa: "LUBTROL",
    distribuidor: "IURY SANDERS",
    profile: "Distribuidor",
  },
  {
    nome: "IURY SANDERS",
    email: "iury@lubtrol.com.br",
    empresa: "LUBTROL",
    profile: "Distribuidor",
  },
  {
    nome: "Teste 1",
    email: "pex.dist.teste1",
    empresa: "QUITE",
    profile: "Distribuidor",
  },
  {
    nome: "Test 2",
    email: "pex.dist.test2",
    empresa: "LUBTROL",
    profile: "Distribuidor",
  },
  // {
  //   nome: "KAMILA SALES",
  //   email: "kamila.sales@nacionallubrificantes.com.br",
  //   empresa: "NACIONAL",
  //   profile: "Distribuidor",
  // },
  // {
  //   nome: "KEILA SILVA",
  //   email: "keila.silva@grupopmz.com.br",
  //   empresa: "SPM",
  //   profile: "Distribuidor",
  // },
  {
    nome: "Ceylla Monick Fernandes Puga",
    email: "ceylla.puga@grupopmz.com.br",
    empresa: "SPM",
    profile: "Distribuidor",
  },
  {
    nome: "Deborah Vitoria Fernandes De Souza",
    email: "deborah.souza@grupopmz.com.br",
    empresa: "SPM",
    profile: "Distribuidor",
  },
];

export const validCredentialsItem = [
  { Avaliador: "Consultor de PD", Item: "3.1.1" },
  { Avaliador: "Consultor de PD", Item: "5.1.1" },
  { Avaliador: "Consultor de PD", Item: "5.1.2" },
  { Avaliador: "Consultor de PD", Item: "5.1.3" },
  { Avaliador: "Consultor de PD", Item: "5.1.4" },
  { Avaliador: "Consultor de PD", Item: "5.1.5" },
  { Avaliador: "Consultor de PD", Item: "5.1.6" },
  { Avaliador: "Consultor de PD", Item: "5.1.7" },
  { Avaliador: "Consultor de PD", Item: "6.1.1" },
  { Avaliador: "Consultor de PD", Item: "6.1.2" },
  { Avaliador: "Consultor de PD", Item: "6.3.1" },
  { Avaliador: "Consultor de PD", Item: "6.3.2" },
  { Avaliador: "Consultor de PD", Item: "6.5.1" },
  { Avaliador: "Consultor de PD", Item: "6.5.2" },
  { Avaliador: "Consultor Franquia", Item: "10.1.1" },
  { Avaliador: "Consultor Franquia", Item: "10.1.2" },
  { Avaliador: "Consultor Franquia", Item: "10.1.3" },
  { Avaliador: "Consultor Franquia", Item: "10.1.4" },
  { Avaliador: "Consultor Franquia", Item: "3.1.2" },
  { Avaliador: "Consultor Franquia", Item: "6.2.1" },
  { Avaliador: "Consultor Franquia", Item: "6.2.2" },
  { Avaliador: "Consultor Franquia", Item: "6.2.3" },
  { Avaliador: "Consultor Franquia", Item: "6.2.4" },
  { Avaliador: "Consultor Franquia", Item: "6.2.5" },
  { Avaliador: "Consultor Franquia", Item: "6.2.6" },
  { Avaliador: "Consultor Franquia", Item: "6.2.7" },
  { Avaliador: "Consultor Franquia", Item: "7.1.1" },
  { Avaliador: "Consultor Franquia", Item: "7.1.2" },
  { Avaliador: "Consultor Franquia", Item: "7.1.3" },
  { Avaliador: "Consultor Franquia", Item: "7.1.4" },
  { Avaliador: "Consultor Franquia", Item: "7.1.5" },
  { Avaliador: "Consultor Franquia", Item: "7.1.6" },
  { Avaliador: "Consultor Franquia", Item: "7.2.1" },
  { Avaliador: "Consultor Franquia", Item: "7.2.10" },
  { Avaliador: "Consultor Franquia", Item: "7.2.11" },
  { Avaliador: "Consultor Franquia", Item: "7.2.12" },
  { Avaliador: "Consultor Franquia", Item: "7.2.13" },
  { Avaliador: "Consultor Franquia", Item: "7.2.14" },
  { Avaliador: "Consultor Franquia", Item: "7.2.2" },
  { Avaliador: "Consultor Franquia", Item: "7.2.3" },
  { Avaliador: "Consultor Franquia", Item: "7.2.4" },
  { Avaliador: "Consultor Franquia", Item: "7.2.5" },
  { Avaliador: "Consultor Franquia", Item: "7.2.6" },
  { Avaliador: "Consultor Franquia", Item: "7.2.7" },
  { Avaliador: "Consultor Franquia", Item: "7.2.8" },
  { Avaliador: "Consultor Franquia", Item: "7.2.9" },
  { Avaliador: "Consultor Franquia", Item: "7.3.1" },
  { Avaliador: "Consultor Franquia", Item: "7.3.10" },
  { Avaliador: "Consultor Franquia", Item: "7.3.2" },
  { Avaliador: "Consultor Franquia", Item: "7.3.3" },
  { Avaliador: "Consultor Franquia", Item: "7.3.4" },
  { Avaliador: "Consultor Franquia", Item: "7.3.5" },
  { Avaliador: "Consultor Franquia", Item: "7.3.6" },
  { Avaliador: "Consultor Franquia", Item: "7.3.7" },
  { Avaliador: "Consultor Franquia", Item: "7.3.8" },
  { Avaliador: "Consultor Franquia", Item: "7.3.9" },
  { Avaliador: "Consultor Franquia", Item: "7.5.1" },
  { Avaliador: "Consultor Franquia", Item: "7.5.2" },
  { Avaliador: "Consultor Franquia", Item: "7.5.3" },
  { Avaliador: "Consultor Franquia", Item: "7.5.4" },
  { Avaliador: "Consultor Franquia", Item: "7.5.5" },
  { Avaliador: "Consultor Franquia", Item: "7.5.6" },
  { Avaliador: "Consultor Franquia", Item: "7.5.7" },
  { Avaliador: "Consultor Franquia", Item: "7.6.1" },
  { Avaliador: "Consultor Franquia", Item: "7.6.2" },
  { Avaliador: "Consultor Franquia", Item: "7.6.3" },
  { Avaliador: "Consultor Franquia", Item: "9.2.1" },
  { Avaliador: "Consultor Franquia", Item: "9.2.2" },
  { Avaliador: "Consultor Franquia", Item: "9.2.3" },
  { Avaliador: "Consultor Franquia", Item: "9.2.4" },
  { Avaliador: "Consultor Franquia", Item: "9.2.5" },
  { Avaliador: "Consultor Franquia", Item: "9.2.6" },
  { Avaliador: "Consultor Franquia", Item: "9.2.7" },
  { Avaliador: "Consultor Franquia", Item: "9.3.1" },
  { Avaliador: "Consultor Franquia", Item: "9.3.2" },
  { Avaliador: "Consultor Franquia", Item: "9.3.3" },
  { Avaliador: "Logistica", Item: "6.4.1" },
  { Avaliador: "Logistica", Item: "6.4.2" },
  { Avaliador: "Logistica", Item: "6.4.5" },
  { Avaliador: "Logistica", Item: "6.4.7" },
  { Avaliador: "Qualidade", Item: "4.1.1" },
  { Avaliador: "Qualidade", Item: "4.1.2" },
  { Avaliador: "Qualidade", Item: "4.1.3" },
  { Avaliador: "Qualidade", Item: "4.2.1" },
  { Avaliador: "Qualidade", Item: "4.4.1" },
  { Avaliador: "Qualidade", Item: "4.4.2" },
  { Avaliador: "Qualidade", Item: "4.4.3" },
  { Avaliador: "Qualidade", Item: "4.4.4" },
  { Avaliador: "Qualidade", Item: "4.4.5" },
  { Avaliador: "Qualidade", Item: "4.4.6" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "1.1.1" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "1.1.10" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "1.1.11" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "1.1.12" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "1.1.13" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "1.1.14" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "1.1.2" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "1.1.3" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "1.1.4" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "1.1.5" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "1.1.6" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "1.1.7" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "1.1.8" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "1.1.9" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "4.3.1" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "4.3.2" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "4.3.3" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "4.3.4" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "4.3.5" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "6.4.3" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "6.4.4" },
  { Avaliador: "Saúde, Segurança e Meio Ambiente (SSMA)", Item: "6.4.6" },
  { Avaliador: "TI", Item: "2.1.1" },
  { Avaliador: "TI", Item: "2.1.2" },
  { Avaliador: "TI", Item: "2.1.3" },
  { Avaliador: "TI", Item: "2.1.4" },
  { Avaliador: "TI", Item: "2.1.5" },
  { Avaliador: "TI", Item: "2.1.6" },
  { Avaliador: "TI", Item: "2.1.7" },
  { Avaliador: "Time de BI", Item: "10.2.1" },
  { Avaliador: "Time de BI", Item: "10.2.2" },
  { Avaliador: "Time de BI", Item: "10.3.1" },
  { Avaliador: "Time de BI", Item: "10.3.2" },
  { Avaliador: "Time de BI", Item: "10.3.3" },
  { Avaliador: "Time de BI", Item: "11.1.1" },
  { Avaliador: "Time de BI", Item: "11.1.2" },
  { Avaliador: "Time de BI", Item: "11.1.3" },
  { Avaliador: "Time de BI", Item: "11.1.4" },
  { Avaliador: "Time de BI", Item: "11.1.5" },
  { Avaliador: "Time de BI", Item: "11.1.6" },
  { Avaliador: "Time de BI", Item: "9.1.1" },
  { Avaliador: "Time de BI", Item: "9.1.2" },
  { Avaliador: "Time de BI", Item: "9.1.3" },
  { Avaliador: "Time de BI", Item: "9.4.1" },
  { Avaliador: "Time de BI", Item: "9.4.2" },
  { Avaliador: "Time de BI", Item: "9.4.3" },
  { Avaliador: "Time de BI", Item: "9.4.4" },
  { Avaliador: "Trade Mkt", Item: "3.1.3" },
  { Avaliador: "Trade Mkt", Item: "7.4.1" },
  { Avaliador: "Trade Mkt", Item: "7.4.2" },
  { Avaliador: "Trade Mkt", Item: "7.7.1" },
  { Avaliador: "Trade Mkt", Item: "7.7.2" },
  { Avaliador: "Trade Mkt", Item: "8.1.1" },
  { Avaliador: "Trade Mkt", Item: "8.1.2" },
  { Avaliador: "Trade Mkt", Item: "8.1.3" },
  { Avaliador: "Trade Mkt", Item: "8.1.4" },
  { Avaliador: "Trade Mkt", Item: "8.1.5" },
  { Avaliador: "Trade Mkt", Item: "8.1.6" },
  { Avaliador: "Trade Mkt", Item: "8.1.7" },
  { Avaliador: "Trade Mkt", Item: "8.1.8" },
  { Avaliador: "Trade Mkt", Item: "8.2.1" },
  { Avaliador: "Trade Mkt", Item: "8.2.2" },
  { Avaliador: "Trade Mkt", Item: "8.2.3" },
  { Avaliador: "Trade Mkt", Item: "8.2.4" },
  { Avaliador: "Trade Mkt", Item: "8.2.5" },
];
