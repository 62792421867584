import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardBody,
  Divider,
  Heading,
  Stack,
  Text,
  Spinner,
  Button,
  Center,
  Progress,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  VStack,
  Badge,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import GruposAvaliadoresPex from "../components/grupos-avaliacao";
import { DATA_AVAL } from "../helpers/mock/data-aval";
import ButtonsYesNo from "../components/button-yes-no";
import ButtonFator from "../components/button-fator-yes-no";
import { useAppContext } from "../context/AppContext";
import ModalAvaliacao from "../components/modal-avaliacao";
import ModalObsAval from "../components/modal-obs-avaliacao";
import { saveAvaliation } from "../api/save-avaliation-api";
import { getToken } from "../api/get-token-api";
import { getAllItemsPex } from "../api/get-items-pex-api";
import { getAllItemsPexWithPoints } from "../api/get-items-pontuacao-api";
import Distribuidores from "../components/distribuidores";
import RefetchAllApis from "../api/refetch-apis";
import { getAllFileNames } from "../api/get-files-name-jc-api";
import AlertBeforeSaveAval from "../components/alert-save-avaliation";
import ModalDescAval from "../components/modal-desc-avaliacao";

const AvaliadorPage = () => {
  const navigate = useNavigate();
  const { distribuidor: distribuidores, filial } = useParams();
  const [grupoSelecionado, setGrupoSelecionado] = useState(null);
  const { distribuidor, setDistribuidorGlobal } = useAppContext();
  const [observacao, setObservacao] = useState("");
  const [respostas, setRespostas] = useState([]);
  const [loading, setLoading] = useState({}); // Estado para rastrear o carregamento individual para cada item
  const [avaliacaoEnviada, setAvaliacaoEnviada] = useState({});
  const [token, setToken] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiDataPontos, setApiDataPontos] = useState([]);
  const [respostasSelecionadas, setRespostasSelecionadas] = useState({});
  const [periodoSelecionado, setPeriodoSelecionado] = useState("Trimestral"); // Estado para controlar o período selecionado
  const [errorMessage, setErrorMessage] = useState(null);
  const [filter, setFilter] = useState("todos"); // Adicionando estado para o filtro
  const [itemsDataFileName, setItemsDataFileName] = useState([]);
  const [counter, setCounter] = useState(0);
  const [pontosSelecionados, setPontosSelecionados] = useState(null);
  const [pontosPorItem, setPontosPorItem] = useState(1);
  const [respostaPorItem, setRespostaPorItem] = useState({});
  const { emailGlobal, setEmailGlobal } = useAppContext();
  const { passwordGlobal, setPasswordGlobal } = useAppContext();
  const [isTeste, setIsteste] = useState(true);
  const { ano, trimestre } = useParams();
  const { anoGlobal, setAnoGlobal } = useAppContext();
  const { trimestreGlobal, setTrimestreGlobal } = useAppContext();
  const { anoGlobalAtivo, setAnoGlobalAtivo } = useAppContext();
  const { trimestreGlobalAtivo, setTrimestreGlobalAtivo } = useAppContext();
  const { acessoUsuario, setAcessoUsuario } = useAppContext();
  const { userProfile } = useAppContext();
  const { nivelAcesso, setNivelAcesso } = useAppContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const frase = periodoSelecionado;
        const primeirasLetras = frase
          .split(" ")
          .map((palavra) => palavra.charAt(0).toUpperCase());

        const tokenData = await getToken(emailGlobal, passwordGlobal);
        setToken(tokenData.access_token);

        let paramForGetItemsPex = 2; // Valor padrão, pode ser ajustado conforme a necessidade

        // Verifica a condição para ajustar o parâmetro
        if (
          anoGlobal === "2023" &&
          (trimestreGlobal === "3" || trimestreGlobal === "4")
        ) {
          paramForGetItemsPex = 1; // Altera o valor para 2 se a condição for verdadeira
        }

        let itemsData, itemsDataPontos, itemsDataFilenames;
        if (periodoSelecionado === "Anual") {
          itemsData = await getAllItemsPex(
            tokenData.access_token,
            paramForGetItemsPex
          );
          itemsDataPontos = await getAllItemsPexWithPoints(
            tokenData.access_token,
            periodoSelecionado,
            distribuidor,
            anoGlobal,
            1, // Define trimestreGlobal como 1 para 'Anual'
            paramForGetItemsPex
          );
          itemsDataFilenames = await getAllFileNames(
            primeirasLetras,
            distribuidor,
            anoGlobal,
            1, // Define trimestreGlobal como 1 para 'Anual'
            paramForGetItemsPex
          );
        } else {
          itemsData = await getAllItemsPex(
            tokenData.access_token,
            paramForGetItemsPex
          );
          itemsDataPontos = await getAllItemsPexWithPoints(
            tokenData.access_token,
            periodoSelecionado,
            distribuidor,
            anoGlobal,
            trimestreGlobal, // Mantém trimestreGlobal como está para outros períodos
            paramForGetItemsPex
          );
          itemsDataFilenames = await getAllFileNames(
            primeirasLetras,
            distribuidor,
            anoGlobal,
            trimestreGlobal, // Mantém trimestreGlobal como está para outros períodos
            paramForGetItemsPex
          );
        }

        setApiData(itemsData);
        setApiDataPontos(itemsDataPontos);
        setItemsDataFileName(itemsDataFilenames);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      clearInterval();
    };
  }, [periodoSelecionado, trimestreGlobal, filter]);

  const { getItemsPexGlobalState } = useAppContext();
  const { getItemsPontuacaoAnualApi } = useAppContext();
  const { getItemsPontuacaoTrimestralApi } = useAppContext();
  const { getFileNameTrimestralApiGlobalState } = useAppContext();
  const { getFileNameAnualApiGlobalState } = useAppContext();
  const fileNameApiSelecionada =
    periodoSelecionado === "Trimestral"
      ? getFileNameTrimestralApiGlobalState
      : getFileNameAnualApiGlobalState;

  const pontuacaoApiSelecionada =
    periodoSelecionado === "Trimestral"
      ? getItemsPontuacaoTrimestralApi
      : getItemsPontuacaoAnualApi;

  const handleGrupoChange = (selectedGrupo) => {
    setGrupoSelecionado(selectedGrupo);
  };

  const handleSaveObservacao = (observacao) => {
    setObservacao(observacao);
  };

  const handleSaveObservacaoText = (observacao) => {
    setObservacao(observacao);
  };

  const handleSaveResposta = (
    numero,
    respostaDoBotao,
    fator,
    avaliador,
    periodo,
    pontos,
    valorNumerico,
    avaliado,
    pontuacao_real
  ) => {
    const token = getToken(emailGlobal, passwordGlobal); // Assume que `getToken` retorna uma Promise que resolve para o token

    // Verificação para decidir entre resposta ou fator
    let respostaFinal, fatorFinal;

    if (typeof respostaDoBotao === "string") {
      if (respostaDoBotao === "P") {
        respostaFinal = "P";
        fatorFinal = 50; // Aqui o fator é 0.5 para respostas parciais
      } else {
        respostaFinal = respostaDoBotao;
        fatorFinal = 0;
      }
    } else if (typeof respostaDoBotao === "number") {
      respostaFinal = respostaDoBotao === 0 ? "N" : "S";
      fatorFinal = respostaDoBotao;
    } else {
      respostaFinal = "";
      fatorFinal = "";
    }

    // Definindo referencia com base no período
    const referencia = periodo === "A" ? 1 : Number(trimestreGlobal);

    const novaResposta = {
      distribuidor: distribuidor,
      numero: numero,
      avaliador: avaliador,
      periodo: periodo,
      ano: Number(anoGlobal),
      referencia: referencia,
      resposta: respostaFinal,
      fator: fatorFinal,
      observacao: observacao,
      atingimento: fator,
    };

    setRespostasSelecionadas((prevRespostas) => ({
      ...prevRespostas,
      [numero]: novaResposta,
    }));

    // Simule uma requisição assíncrona, você pode substituir por sua lógica real
    // await saveAvaliation(token.access_token, [novaResposta]);

    setAvaliacaoEnviada((prevAvaliacaoEnviada) => ({
      ...prevAvaliacaoEnviada,
      [numero]: true,
    }));

    // Atualizar o estado local com o valor de pontos para o item específico
    setPontosPorItem((prevPontos) => ({ ...prevPontos, [numero]: pontos }));

    // Não há mais bloco de try-catch ou simulação de erro
  };

  const handleSaveRespostaYESNO = ({
    resposta,
    fator,
    avaliado,
    pontos,
    pontuacao_real,
    pontosSelecionados,
    numero,
    avaliador,
    periodo,
  }) => {
    const token = getToken(emailGlobal, passwordGlobal);

    let respostaFinal, fatorFinal;

    if (typeof resposta === "string") {
      respostaFinal = resposta;
      fatorFinal = 0;
    } else if (typeof resposta === "number") {
      respostaFinal = resposta === 0 ? "N" : "S";
      fatorFinal = resposta;
    } else {
      respostaFinal = "";
      fatorFinal = "";
    }

    const novaResposta = {
      distribuidor: distribuidor,
      numero: numero,
      avaliador: avaliador,
      periodo: periodo,
      ano: Number(anoGlobal),
      referencia: Number(trimestreGlobal),
      resposta: respostaFinal,
      fator: fatorFinal,
      observacao: observacao,
      atingimento: fator,
    };

    setRespostasSelecionadas((prevRespostas) => ({
      ...prevRespostas,
      [numero]: novaResposta,
    }));

    setAvaliacaoEnviada((prevAvaliacaoEnviada) => ({
      ...prevAvaliacaoEnviada,
      [numero]: true,
    }));

    setPontosPorItem((prevPontos) => ({ ...prevPontos, [numero]: pontos }));

    // Adicione sua lógica assíncrona aqui, se necessário
  };

  // Função para unir os arrays usando o número como identificador
  const duasApisJuntas = (apiData, apiDataPontos) => {
    return apiData.map((item1) => {
      const item2 = apiDataPontos.find(
        (item2) => item2.numero === item1.numero
      );
      return { ...item1, ...item2 };
    });
  };

  const resetAvaliacaoEnviada = () => {
    setAvaliacaoEnviada({});
  };

  const resetLoading = () => {
    setLoading({});
  };
  const sortByNumero = (a, b) => {
    const numeroA = a.numero.split(".").map(Number);
    const numeroB = b.numero.split(".").map(Number);

    for (let i = 0; i < Math.max(numeroA.length, numeroB.length); i++) {
      if (numeroA[i] !== undefined && numeroB[i] === undefined) {
        return 1;
      }
      if (numeroA[i] === undefined && numeroB[i] !== undefined) {
        return -1;
      }
      if (numeroA[i] < numeroB[i]) {
        return -1;
      }
      if (numeroA[i] > numeroB[i]) {
        return 1;
      }
    }

    return 0;
  };

  const arrayUnido = duasApisJuntas(apiDataPontos, apiData).sort(sortByNumero);

  // Agrupar os itens por capítulo
  const itensPorCapitulo = arrayUnido.reduce((acc, item) => {
    const capitulo = item.capitulo;
    if (!acc[capitulo]) {
      acc[capitulo] = [];
    }

    acc[capitulo].push(item);
    return acc;
  }, {});

  const handlePeriodoChange = (novoPeriodo) => {
    setPeriodoSelecionado(novoPeriodo);
  };

  const calculateFilledItems = (items) => {
    const preenchidos = items.filter((item) => item.avaliado === "S").length;
    return preenchidos;
  };

  const filterItems = (items, filter) => {
    switch (filter) {
      case "avaliado":
        const avaliados = items.filter((item) => item.avaliado === "S");
        return avaliados;

      case "naoAvaliado":
        const naoAvaliados = items.filter((item) => item.avaliado !== "S");
        return naoAvaliados;

      case "todos":
      default:
        return items;
    }
  };
  const chapterPermissionsMap = {};
  const userAccess = acessoUsuario;

  if (Array.isArray(userAccess)) {
    userAccess.forEach((access) => {
      for (let i = 0; i <= 10; i++) {
        const chapterKey = `chapter${i}`;
        const chapterValue = access[chapterKey];

        if (chapterValue !== null && chapterValue !== undefined) {
          // Remover espaços extras no meio da string
          const normalizedChapterValue = chapterValue.replace(/\s+/g, " ");
          chapterPermissionsMap[normalizedChapterValue] = true;
        }
      }
    });
  } else if (typeof userAccess === "object") {
    for (let i = 0; i <= 10; i++) {
      const chapterKey = `chapter${i}`;
      const chapterValue = userAccess[chapterKey];

      if (chapterValue !== null && chapterValue !== undefined) {
        // Remover espaços extras no meio da string
        const normalizedChapterValue = chapterValue.replace(/\s+/g, " ");
        chapterPermissionsMap[normalizedChapterValue] = true;
      }
    }
  }

  const userNivelAcess = nivelAcesso;

  function removeDuplicates(array) {
    const seen = new Set();
    return array.filter((item) => {
      const key = item.numero; // Ajuste a chave conforme necessário
      return seen.has(key) ? false : seen.add(key);
    });
  }

  const itemsFiltered = Object.entries(itensPorCapitulo).reduce(
    (acc, [capitulo, itens]) => {
      const normalizedCapitulo = capitulo.replace(/\s+/g, " ");
      const hasPermission = chapterPermissionsMap[normalizedCapitulo];

      if (hasPermission) {
        const filteredItens = filterItems(itens, filter);
        const isGlobalAccess = userNivelAcess === "global";
        const filteredByAvaliador = isGlobalAccess
          ? filteredItens
          : filteredItens.filter((item) => item.avaliador === userNivelAcess);

        const uniqueItems = removeDuplicates(filteredByAvaliador);

        if (uniqueItems.length > 0) {
          acc[normalizedCapitulo] = uniqueItems;
        }
      }
      return acc;
    },
    {}
  );

  const calculateProgressColor = () => {
    const totalItensExibidos = Object.values(itemsFiltered)
      .flat()
      .filter((item) => item.avaliado !== undefined).length;

    const percentFilled =
      totalItensExibidos > 0
        ? (itensPreenchidos / totalItensExibidos) * 100
        : 0;

    if (percentFilled <= 30) {
      return "red";
    } else if (percentFilled <= 70) {
      return "yellow";
    } else {
      return "green";
    }
  };

  const totalItensExibidos = Object.values(itemsFiltered)
    .flat()
    .filter((item) => item.avaliado !== undefined).length;

  const itensPreenchidos = calculateFilledItems(
    Object.values(itemsFiltered).flat()
  );

  const combinarDados = (dadosItems, itemsDataFileName) => {
    return dadosItems.map((item) => {
      const arquivos =
        itemsDataFileName.find((file) => file.chave === item.chave)?.arquivos ||
        [];
      return { ...item, arquivos };
    });
  };

  const dadosCombinados = combinarDados(
    duasApisJuntas(apiData, apiDataPontos),
    itemsDataFileName
  );

  const realOnClick = () => {};

  const handleAlertBeforeSave = (
    numero,
    titulo,
    pontuacaoReal,
    pontuacaoMaxima,
    arquivos,
    pontos
  ) => {
    setPontosSelecionados(pontos);
    // Pode realizar outras ações com a informação 'numero'
  };

  const handleSaveAval = (numero) => {
    // Encontrar o item no array e modificar a propriedade 'avaliado'
    const updatedData = apiData.map((item) =>
      item.numero === numero ? { ...item, avaliado: "S" } : item
    );

    // Atualizar o estado com os novos dados
    setApiData(updatedData);
  };

  const hasFilteredItems = Object.values(itemsFiltered).some(
    (itens) => itens.length > 0
  );

  return (
    <Box>
      {isLoading ? (
        <>
          <Center h="100vh">
            <VStack>
              <Spinner size="xl" color="black" thickness="4px" />
              <Text mt="4">Aguarde... </Text>
            </VStack>
          </Center>
        </>
      ) : (
        <div>
          <Heading as="h1" size="xl">
            Distribuidor: {distribuidores} - {anoGlobal}/
            {parseInt(anoGlobal) + 1} - {trimestreGlobal}
          </Heading>
          <Heading as="h3" size="md">
            Avaliador(a):{" "}
            {userAccess && userAccess.length > 0
              ? userAccess[0].name
              : "Nome não disponível"}
          </Heading>
          <Stack direction="row" marginTop={"10px"}>
            <Button
              colorScheme={periodoSelecionado === "Anual" ? "yellow" : "gray"}
              onClick={() => handlePeriodoChange("Anual")}
            >
              Anual
            </Button>
            <Button
              colorScheme={
                periodoSelecionado === "Trimestral" ? "yellow" : "gray"
              }
              onClick={() => handlePeriodoChange("Trimestral")}
            >
              Trimestral
            </Button>
          </Stack>
          <Text mt="2" fontSize="lg" color="black" fontWeight="bold">
            {`Itens avaliados: ${itensPreenchidos}/${totalItensExibidos} (${(totalItensExibidos >
            0
              ? (itensPreenchidos / totalItensExibidos) * 100
              : 0
            ).toFixed(2)}%)`}
          </Text>
          <Progress
            mt="4"
            value={(totalItensExibidos > 0
              ? (itensPreenchidos / totalItensExibidos) * 100
              : 0
            ).toFixed(2)}
            colorScheme={calculateProgressColor()}
            hasStripe
          />
          <Divider borderWidth="2px" marginTop={"10px"} />
          <Button
            mt="10px"
            mr="5px"
            onClick={() => setFilter("avaliado")}
            colorScheme={filter === "avaliado" ? "green" : "gray"}
          >
            Ver Itens Avaliados
          </Button>
          <Button
            mt="10px"
            mr="5px"
            onClick={() => setFilter("naoAvaliado")}
            colorScheme={filter === "naoAvaliado" ? "green" : "gray"}
          >
            Ver Itens não Avaliados
          </Button>
          <Button
            mt="10px"
            mr="5px"
            onClick={() => setFilter("todos")}
            colorScheme={filter === "todos" ? "green" : "gray"}
          >
            Ver Todos
          </Button>
          <Distribuidores />
          {!hasFilteredItems && (
            <Text mt="10px" color="red.500" fontSize="20px">
              Nenhum item encontrado após os filtros.
            </Text>
          )}
          {Object.entries(itemsFiltered).map(([capitulo, itens]) => (
            <Card key={capitulo} width={"83vw"} marginTop={"10px"}>
              <CardBody>
                <Heading as="h2" size="lg">
                  {capitulo}
                </Heading>
                {itens.map((item, index) => (
                  <div key={index}>
                    <Card
                      mt="15px"
                      position="relative"
                      _before={{
                        content: '""',
                        display: "block",
                        position: "absolute",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: "6px",
                        bg:
                          dadosCombinados.find(
                            (combinedItem) =>
                              combinedItem.numero === item.numero
                          )?.arquivos.length > 0
                            ? "green.500"
                            : item.evidencia === "N"
                            ? "gray.500"
                            : "none",
                        borderRadius: "4px 4px",
                      }}
                    >
                      {" "}
                      <CardBody>
                        <Heading as="h3" size="md">
                          {`${item.numero} - ${item.titulo}`}
                        </Heading>
                        <Stack direction="column" spacing={1}>
                          <Badge
                            ml="15px"
                            variant="outline"
                            width="200px"
                            // color="black"
                            // bg="#FFC050"
                            colorScheme="green"
                            mt="10px"
                            fontSize="sm"
                          >
                            Pontuação Máxima: {item.pontuacao_maxima}
                          </Badge>
                          {item.criterio === "V" && (
                            <Badge
                              ml="15px"
                              variant="outline"
                              width="200px"
                              mt="5px"
                              fontSize="sm"
                              colorScheme="green"
                              style={{
                                transition: "background-color 0.5s",
                                backgroundColor: pontosPorItem[item.numero]
                                  ? "green.200"
                                  : "red.200",
                              }}
                              _hover={{ backgroundColor: "green.200" }}
                            >
                              Pontuação Real:{" "}
                              {pontosPorItem[item.numero] === 0
                                ? 0
                                : pontosPorItem[item.numero] ||
                                  item.pontuacao_real}
                            </Badge>
                          )}
                          {item.criterio !== "V" && (
                            <Badge
                              ml="15px"
                              variant="outline"
                              width="200px"
                              mt="5px"
                              fontSize="sm"
                              colorScheme="green"
                              style={{
                                transition: "background-color 0.5s",
                                backgroundColor: pontosPorItem[item.numero]
                                  ? "green.200"
                                  : "red.200",
                              }}
                              _hover={{ backgroundColor: "green.200" }}
                            >
                              Pontuação Real:{" "}
                              {respostasSelecionadas[item.numero] &&
                              respostasSelecionadas[item.numero].resposta ===
                                "P"
                                ? 50
                                : respostasSelecionadas[item.numero] &&
                                  respostasSelecionadas[item.numero]
                                    .resposta === "S"
                                ? item.pontuacao_maxima
                                : respostasSelecionadas[item.numero] &&
                                  respostasSelecionadas[item.numero]
                                    .resposta === "N"
                                ? 0
                                : item.resposta === "S"
                                ? item.pontuacao_real || item.pontuacao_maxima
                                : 0}
                            </Badge>
                          )}
                        </Stack>
                        <Stack direction="row">
                          {item.evidencia === "N" ? (
                            <Stack>
                              <Box
                                mt="20px"
                                p="4"
                                bg="#718096"
                                borderRadius="md"
                                textAlign="center"
                                w="420px"
                              >
                                <Text
                                  color="white"
                                  fontSize="lg"
                                  fontWeight="bold"
                                >
                                  Item de apuração automática
                                </Text>
                              </Box>
                              {item.observacao !== "" && (
                                <Box
                                  border="1px solid black"
                                  borderRadius="md"
                                  p="4"
                                >
                                  <Text fontSize="lg" fontWeight="bold">
                                    Observação sobre apuração automática:
                                  </Text>
                                  <Text>{item.observacao}</Text>
                                </Box>
                              )}
                            </Stack>
                          ) : (
                            <>
                              {item.criterio === "V" ? (
                                <ButtonFator
                                  criterio={item.criterio}
                                  fator={item.fator}
                                  operador={item.operador}
                                  range={item.range}
                                  realOnClick
                                  onSave={(
                                    resposta,
                                    fator,
                                    avaliado,
                                    pontos,
                                    pontuacao_real,
                                    pontosSelecionados
                                  ) => {
                                    handleSaveResposta(
                                      item.numero,
                                      resposta,
                                      fator,
                                      item.avaliador,
                                      item.periodo,
                                      pontos,
                                      avaliado,
                                      pontuacao_real,
                                      pontosSelecionados
                                    );
                                  }}
                                  loading={loading[item.numero]}
                                  avaliado={item.avaliado}
                                  pontuacao_real={item.pontuacao_real}
                                  resposta={item.resposta}
                                />
                              ) : (
                                <ButtonsYesNo
                                  criterio={item.criterio}
                                  fator={item.fator}
                                  operador={item.operador}
                                  range={item.range}
                                  realOnClick
                                  onSave={(
                                    resposta,
                                    fator,
                                    avaliado,
                                    pontos,
                                    pontuacao_real
                                  ) => {
                                    handleSaveResposta(
                                      item.numero,
                                      resposta,
                                      fator,
                                      item.avaliador,
                                      item.periodo,
                                      pontos,
                                      avaliado,
                                      pontuacao_real
                                    );
                                  }}
                                  loading={loading[item.numero]}
                                  avaliado={item.avaliado}
                                  pontuacao_real={item.pontuacao_real}
                                  resposta={item.resposta}
                                  item={item}
                                />
                              )}
                              <ModalAvaliacao
                                chave={item.chave}
                                arrayUnido={arrayUnido}
                              />
                              <ModalDescAval
                                itemDesc={item.descricao}
                                itemNumero={item.numero}
                              />
                              {item.observacao && (
                                <ModalObsAval
                                  itemObs={item.observacao}
                                  onSave={handleSaveObservacao}
                                />
                              )}
                            </>
                          )}
                        </Stack>
                        {loading[item.numero] && <Spinner size="sm" />}
                        {item.avaliado === "S" && item.evidencia === "N" ? (
                          <Text
                            ml="15px"
                            color="green.500"
                            fontSize="sm"
                            marginTop="2"
                          >
                            Avaliação automática recebida.
                          </Text>
                        ) : item.avaliado === "S" && item.evidencia === "S" ? (
                          <Text
                            ml="15px"
                            color="green.500"
                            fontSize="sm"
                            marginTop="2"
                          >
                            Avaliação enviada com sucesso!
                          </Text>
                        ) : avaliacaoEnviada[item.numero] === false ? (
                          <Text
                            ml="15px"
                            color="red.500"
                            fontSize="sm"
                            marginTop="2"
                          >
                            Erro ao enviar a avaliação. Tente novamente.
                          </Text>
                        ) : null}
                        {/* Verifica se o array de arquivos não está vazio antes de renderizar */}
                        {dadosCombinados.find(
                          (combinedItem) => combinedItem.numero === item.numero
                        )?.arquivos.length > 0 && (
                          <Accordion allowToggle mt="15px">
                            <AccordionItem>
                              <AccordionButton>
                                <Box flex="1" textAlign="left">
                                  <Text color="gray" fontWeight="bold">
                                    Arquivos Anexados (
                                    {
                                      dadosCombinados.find(
                                        (combinedItem) =>
                                          combinedItem.numero === item.numero
                                      ).arquivos.length
                                    }
                                    )
                                  </Text>
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                              <AccordionPanel>
                                <ul style={{ listStyleType: "none" }}>
                                  {dadosCombinados
                                    .find(
                                      (combinedItem) =>
                                        combinedItem.numero === item.numero
                                    )
                                    .arquivos.map((arquivo, index) => (
                                      <li key={index}>{arquivo}</li>
                                    ))}
                                </ul>
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                        )}
                      </CardBody>
                      {userProfile === "Administrador" && (
                        <AlertBeforeSaveAval
                          arquivos={
                            dadosCombinados.find(
                              (combinedItem) =>
                                combinedItem.numero === item.numero
                            )?.arquivos || []
                          }
                          criterio={item.criterio}
                          onSave={(numero) => handleSaveAval(numero)}
                          avaliado={item.avaliado}
                          numero={item.numero}
                          titulo={item.titulo}
                          pontuacaoDoItem={pontosPorItem[item.numero]}
                          pontuacaoReal={item.pontuacao_real}
                          pontuacaoMaxima={item.pontuacao_maxima}
                          novaResposta={respostasSelecionadas[item.numero]} // Adicione a novaResposta aqui
                          onAlertBeforeSave={(pontosSelecionados) =>
                            handleAlertBeforeSave(
                              item.numero,
                              item.titulo,
                              item.pontuacao_real,
                              item.pontuacao_maxima
                            )
                          }
                        />
                      )}
                      {userProfile !== "Administrador" &&
                        anoGlobal === anoGlobalAtivo &&
                        trimestreGlobal === trimestreGlobalAtivo &&
                        item.evidencia !== "N" && (
                          <AlertBeforeSaveAval
                            arquivos={
                              dadosCombinados.find(
                                (combinedItem) =>
                                  combinedItem.numero === item.numero
                              )?.arquivos || []
                            }
                            criterio={item.criterio}
                            onSave={(numero) => handleSaveAval(numero)}
                            avaliado={item.avaliado}
                            numero={item.numero}
                            titulo={item.titulo}
                            pontuacaoDoItem={pontosPorItem[item.numero]}
                            pontuacaoReal={item.pontuacao_real}
                            pontuacaoMaxima={item.pontuacao_maxima}
                            novaResposta={respostasSelecionadas[item.numero]} // Adicione a novaResposta aqui
                            onAlertBeforeSave={(pontosSelecionados) =>
                              handleAlertBeforeSave(
                                item.numero,
                                item.titulo,
                                item.pontuacao_real,
                                item.pontuacao_maxima
                              )
                            }
                          />
                        )}
                    </Card>

                    <Divider borderWidth="2px" marginTop={"10px"} />
                  </div>
                ))}
              </CardBody>
            </Card>
          ))}
          {errorMessage && (
            <Text fontSize="lg" color="red.500" marginTop="4">
              {errorMessage}
            </Text>
          )}

          {hasFilteredItems && (
            <Text mt="10px" fontSize="sm" marginTop="2" color="red.500">
              A mensagem de sucesso é exibida somente após o envio da avaliação.
            </Text>
          )}

          {/* <Text mt="10px" fontSize="sm" marginTop="2" color="red.500">
            A mensagem de sucesso é exibida somente após o envio da avaliação.
          </Text> */}
          {/* <RefetchAllApis buttonText="Atualizar itens avaliados" /> */}
        </div>
      )}
    </Box>
  );
};

export default AvaliadorPage;
