import { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Textarea,
  Button,
  VStack,
  Text,
  Box,
  Flex,
  IconButton,
  Input,
  Image,
  Link,
} from "@chakra-ui/react";
import { IoCloseCircleOutline } from "react-icons/io5";

// Mock para o histórico de respostas
const mockData = [];

const ContestacaoModal = ({
  isOpen,
  onClose,
  item,
  emailBody,
  setEmailBody,
  selectedItem,
}) => {
  const [contestation, setContestation] = useState("");
  const [response, setResponse] = useState("");
  const [replica, setReplica] = useState("");
  const [treplica, setTreplica] = useState("");
  const [phase, setPhase] = useState("contestation"); // Controla a fase atual
  const [history, setHistory] = useState([]); // Estado para manter o histórico
  const charLimit = 250; // Limite de caracteres
  const [attachments, setAttachments] = useState([]); // Estado para anexos

  // useEffect para limpar o modal ao abrir um novo item
  useEffect(() => {
    if (isOpen) {
      // Limpa os estados quando o modal é aberto para um novo item
      setContestation("");
      setResponse("");
      setReplica("");
      setTreplica("");
      setPhase("contestation");
      setHistory([]);
    }
  }, [isOpen, selectedItem]); // Dependências: isOpen e selectedItem

  // Função para avançar para a próxima fase e atualizar o mockData
  const handleNextPhase = () => {
    switch (phase) {
      case "contestation":
        updateHistory("Contestação", contestation);
        setPhase("response");
        break;
      case "response":
        updateHistory("Resposta", response);
        setPhase("contestationResponse");
        break;
      case "contestationResponse":
        updateHistory("Contestação da Resposta", contestation);
        setPhase("replica");
        break;
      case "replica":
        updateHistory("Réplica", replica);
        setPhase("contestationReplica");
        break;
      case "contestationReplica":
        updateHistory("Contestação da Réplica", treplica);
        setPhase("treplica");
        break;
      case "treplica":
        updateHistory("Tréplica", treplica);
        handleSendEmail(); // Simula envio de email ao finalizar
        break;
      default:
        break;
    }
  };

  // Função para atualizar o histórico
  const updateHistory = (type, message) => {
    const newEntry = {
      type,
      message,
      timestamp: new Date().toLocaleString(), // Adiciona timestamp para cada entrada
    };
    const updatedHistory = [...history, newEntry];
    setHistory(updatedHistory);
    mockData.push(newEntry); // Simula armazenamento na API
  };

  const handleSendEmail = () => {
    const emailData = {
      numeroItem: selectedItem?.numero || "",
      contestation,
      response,
      replica,
      treplica,
    };
    console.log("Email enviado com dados:", emailData);
    console.log("Mock data atualizada:", mockData);

    // Fechar modal após enviar
    onClose();
  };

  // Função para lidar com o upload de anexos
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setAttachments((prevAttachments) => [...prevAttachments, ...files]);
  };

  // Função para remover um anexo
  const handleRemoveAttachment = (index) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((_, i) => i !== index)
    );
  };

  console.log("selectedItem", selectedItem);

  // Função para renderizar visualização do arquivo
  const renderAttachmentPreview = (file) => {
    const fileType = file.type;

    // Para imagens
    if (fileType.startsWith("image/")) {
      const imageUrl = URL.createObjectURL(file);
      return <Image src={imageUrl} alt={file.name} maxW="200px" />;
    }

    // Para PDFs
    if (fileType === "application/pdf") {
      const pdfUrl = URL.createObjectURL(file);
      return (
        <Link href={pdfUrl} target="_blank" color="blue.500">
          Visualizar PDF
        </Link>
      );
    }

    // Para outros tipos de arquivos
    return (
      <Link href={URL.createObjectURL(file)} target="_blank" color="blue.500">
        Baixar {file.name}
      </Link>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Contestação do item {selectedItem ? selectedItem.numero : ""}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDirection="column" height="60vh">
          {/* Histórico em estilo de chat */}
          <Box
            flex={1}
            overflowY="auto"
            p={4}
            mb={4}
            bg="gray.200"
            borderRadius="md"
          >
            <VStack align="start" spacing={4}>
              {history.length === 0 ? (
                <Text>Nenhum histórico até o momento.</Text>
              ) : (
                history.map((entry, index) => (
                  <Flex
                    key={index}
                    p={2}
                    borderRadius="md"
                    alignSelf={
                      entry.type === "Contestação" ||
                      entry.type === "Contestação da Resposta" ||
                      entry.type === "Contestação da Réplica"
                        ? "flex-start"
                        : "flex-end"
                    }
                    bg={
                      entry.type.includes("Contestação")
                        ? "blue.50"
                        : "green.50"
                    }
                    maxW="70%"
                    direction="column"
                  >
                    <Text fontWeight="bold">{entry.type}:</Text>
                    <Text>{entry.message}</Text>
                    <Text fontSize="sm" color="gray.500" align="right">
                      {entry.timestamp}
                    </Text>
                  </Flex>
                ))
              )}
            </VStack>
          </Box>

          {/* Fase de contestação */}
          {phase === "contestation" && (
            <FormControl>
              <FormLabel>Contestação</FormLabel>
              <Textarea
                placeholder="Escreva sua contestação..."
                value={contestation}
                onChange={(e) => setContestation(e.target.value)}
                maxLength={charLimit} // Limita a 250 caracteres
              />
              <Text
                textAlign="right"
                color={contestation.length > charLimit ? "red.500" : "gray.500"}
              >
                {contestation.length}/{charLimit}
              </Text>
            </FormControl>
          )}

          {/* Fase de resposta */}
          {phase === "response" && (
            <FormControl>
              <FormLabel>Resposta</FormLabel>
              <Textarea
                placeholder="Escreva a resposta..."
                value={response}
                onChange={(e) => setResponse(e.target.value)}
                maxLength={charLimit} // Limita a 250 caracteres
              />
              <Text
                textAlign="right"
                color={response.length > charLimit ? "red.500" : "gray.500"}
              >
                {response.length}/{charLimit}
              </Text>
            </FormControl>
          )}

          {/* Fase de contestação da resposta */}
          {phase === "contestationResponse" && (
            <FormControl>
              <FormLabel>Contestação da Resposta</FormLabel>
              <Textarea
                placeholder="Escreva sua contestação da resposta..."
                value={contestation}
                onChange={(e) => setContestation(e.target.value)}
                maxLength={charLimit} // Limita a 250 caracteres
              />
              <Text
                textAlign="right"
                color={contestation.length > charLimit ? "red.500" : "gray.500"}
              >
                {contestation.length}/{charLimit}
              </Text>
            </FormControl>
          )}

          {/* Fase de réplica */}
          {phase === "replica" && (
            <FormControl>
              <FormLabel>Réplica</FormLabel>
              <Textarea
                placeholder="Escreva a réplica..."
                value={replica}
                onChange={(e) => setReplica(e.target.value)}
                maxLength={charLimit} // Limita a 250 caracteres
              />
              <Text
                textAlign="right"
                color={replica.length > charLimit ? "red.500" : "gray.500"}
              >
                {replica.length}/{charLimit}
              </Text>
            </FormControl>
          )}

          {/* Fase de contestação da réplica */}
          {phase === "contestationReplica" && (
            <FormControl>
              <FormLabel>Contestação da Réplica</FormLabel>
              <Textarea
                placeholder="Escreva sua contestação da réplica..."
                value={treplica}
                onChange={(e) => setTreplica(e.target.value)}
                maxLength={charLimit} // Limita a 250 caracteres
              />
              <Text
                textAlign="right"
                color={treplica.length > charLimit ? "red.500" : "gray.500"}
              >
                {treplica.length}/{charLimit}
              </Text>
            </FormControl>
          )}

          {/* Fase de tréplica */}
          {phase === "treplica" && (
            <FormControl>
              <FormLabel>Tréplica</FormLabel>
              <Textarea
                placeholder="Escreva a tréplica..."
                value={treplica}
                onChange={(e) => setTreplica(e.target.value)}
                maxLength={charLimit} // Limita a 250 caracteres
              />
              <Text
                textAlign="right"
                color={treplica.length > charLimit ? "red.500" : "gray.500"}
              >
                {treplica.length}/{charLimit}
              </Text>
            </FormControl>
          )}
        </ModalBody>

        {/* Lista de anexos com visualização */}
        {attachments.length > 0 && (
          <Box mb={4} ml={5}>
            <Text fontWeight="bold">Arquivos anexados:</Text>
            <VStack align="start" spacing={2}>
              {attachments.map((file, index) => (
                <Flex key={index} align="center" w="100%">
                  <Box flex="1">{renderAttachmentPreview(file)}</Box>
                  <IconButton
                    size="sm"
                    icon={<IoCloseCircleOutline />}
                    onClick={() => handleRemoveAttachment(index)}
                    aria-label="Remover anexo"
                  />
                </Flex>
              ))}
            </VStack>
          </Box>
        )}

        {/* Input para anexos */}
        <Box mb={4}>
          <Text fontWeight="bold" mb={2} ml={6}>
            Anexar arquivos:
          </Text>
          <Input type="file" multiple onChange={handleFileChange} />
        </Box>

        {/* Lista de anexos */}
        {attachments.length > 0 && (
          <Box mb={4} ml={6}>
            <Text fontWeight="bold">Arquivos anexados:</Text>
            <VStack align="start" spacing={2}>
              {attachments.map((file, index) => (
                <Flex key={index} align="center">
                  <Text mr={2}>{file.name}</Text>
                  <IconButton
                    size="sm"
                    icon={<IoCloseCircleOutline />}
                    onClick={() => handleRemoveAttachment(index)}
                    aria-label="Remover anexo"
                  />
                </Flex>
              ))}
            </VStack>
          </Box>
        )}
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleNextPhase}
            isDisabled={
              (phase === "contestation" && !contestation) ||
              (phase === "response" && !response) ||
              (phase === "contestationResponse" && !contestation) ||
              (phase === "replica" && !replica) ||
              (phase === "contestationReplica" && !treplica) ||
              (phase === "treplica" && !treplica)
            }
          >
            {phase === "treplica" ? "Enviar" : "Enviar"}
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ContestacaoModal;
