"use client";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useLocation,
} from "react-router-dom";
import PexIcon from "../helpers/mock/files/pex-removebg-preview.png";
import {
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Image,
  Stack,
  useDisclosure,
  Drawer,
  DrawerContent,
  Heading,
  Text,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Tooltip } from "@chakra-ui/react";
import { MdDashboard } from "react-icons/md";
import {
  MdAddCircle,
  MdBorderColor,
  MdCheck,
  MdFileUpload,
} from "react-icons/md";
import AdicionarItemComponent from "../components/adicionar-item";
import ExampleWithProviders from "../components/editar-remover-item";
import AvaliadorPage from "./avaliar-item";
import { EscolhaDistribuidor } from "./escolhe-distribuidor";
import PaginaPontos from "./pontos";
import AnexarEvidencias from "./anexar-evidencias";
import PontosPorItens from "../components/pontuacao-dist-pex";
import { MdOutlineDashboard } from "react-icons/md";
import UserCheck from "./user-route-test";
import { useAppContext } from "../context/AppContext";
import { getToken } from "../api/get-token-api";
import LoginPage from "../pages/login-test";
import { IoExit } from "react-icons/io5";
import { FaStar } from "react-icons/fa";
import BlinkingButton from "../components/blinking-button";
import NotFound from "../components/not-found";
import { IoMdDownload } from "react-icons/io";
import DownloadButton from "../components/download-pex-button";
import { FaExclamationTriangle } from "react-icons/fa";
import { FaRegQuestionCircle } from "react-icons/fa";
import PontosPorItensAval from "../components/pontuacao-dist-aval-pex";
import { RiDashboardFill } from "react-icons/ri";
import ForgotPassword from "./alterar-senha";
import { FaUnlock } from "react-icons/fa";
import RecoverPassword from "./recuperar-senha";
import PDFViewer from "../components/manual-pex-pdf";
import TabelaAvaliadoresTdsPontos from "../components/all-points-aval-dists";
import { BsCalendar2DateFill } from "react-icons/bs";
import PeriodoAvaliacao from "./periodo-avaliacao";
import paginaManuais from "./manuais-pex";
import PaginaManual from "./manuais-pex";
import SelecionarPeriodoAnexo from "./selecionar-periodo-anexar";
import SelecionarPeriodoAvaliador from "./selecionar-periodo-avaliar";
import SelecionarPeriodoObservacoes from "./selecionar-periodo-obs-dists";
import SelecionarPeriodoPontuacao from "./selecionar-periodo-pontuacao";
import { BsFillEyeFill } from "react-icons/bs";
import AcompanharContestar from "./acompanhar-contestar";

const LinkItems = [
  {
    name: "Resultados PEX",
    icon: FaStar,
    path: "https://app.powerbi.com/groups/me/apps/79e64e1a-6114-42f3-90ba-ed0163f05ed5/reports/996746f4-2794-408f-8acd-d177815d831f/ReportSection81206d230301e401989b?ctid=e7c411a6-9013-4967-a5b1-3d08f9edc13e&experience=power-bi",
  },
  { name: "Adicionar Item", icon: MdAddCircle, path: "/adicionar-item" },
  {
    name: "Período de Avaliação",
    icon: BsCalendar2DateFill,
    path: "/periodo-avaliacao",
  },

  {
    name: "Editar/Remover Item",
    icon: MdBorderColor,
    path: "/editar-remover-item",
  },
  {
    name: "Observação dos Avaliadores",
    icon: FaExclamationTriangle,
    path: "/observacoes",
  },
  {
    name: "Resumo das Avaliações",
    icon: RiDashboardFill,
    path: "/dashboard-avaliador/",
  },

  { name: "Avaliar", icon: MdCheck, path: "/avaliador" },
  { name: "Anexar Evidências", icon: MdFileUpload, path: "/evidencias" },
  { name: "Manuais PEX", icon: IoMdDownload, path: "/manuais-pex" },
  { name: "Alterar Senha", icon: FaUnlock, path: "/alterar-senha" },
  {
    name: "Acompanhamento e Contestações",
    icon: BsFillEyeFill,
    path: "/acompanhar-contestar",
  },

  // { name: "Sair", icon: IoExit, path: "/" },

  // {
  //   name: "Pontuação II",
  //   icon: MdOutlineDashboard,
  //   path: "/dashboard-avaliador",
  // },
];

const SidebarContent = ({
  onClose,
  userType,
  onUserTypeChange,
  onLogoClick,
  onLogout,
  ...rest
}) => {
  const handleClick = (buttonText) => {
    onUserTypeChange(buttonText);
  };

  const { empresaGlobal } = useAppContext();

  const location = useLocation(); // Obtém a localização atual

  const isActive = (path) => {
    // Função para verificar se a rota está ativa
    return location.pathname.startsWith(path);
  };

  // const handleLogout = () => {
  //   // Ao clicar no botão "Sair", define o userProfile como uma string vazia
  //   localStorage.removeItem("userProfile");
  //   <Navigate to="/" />;
  // };

  const shouldRenderLink = (link, userType) => {
    return (
      (link.name === "Anexar Evidências" && userType === "Distribuidor") ||
      (link.name === "Observação dos Avaliadores" &&
        userType === "Distribuidor") ||
      (link.name === "Resumo das Avaliações" && userType === "Avaliador") ||
      (link.name === "Manuais PEX" && userType === "Distribuidor") ||
      (link.name === "Sair" && userType === "Distribuidor") ||
      (link.name === "Avaliar" && userType === "Avaliador") ||
      (link.name === "Manuais PEX" && userType === "Avaliador") ||
      (link.name === "Resultados PEX" && userType === "Distribuidor") ||
      (link.name === "Alterar Senha" && userType === "Distribuidor") ||
      (link.name === "Alterar Senha" && userType === "Avaliador") ||
      (link.name === "Acompanhamento e Contestações" &&
        userType === "Distribuidor" &&
        empresaGlobal === "FORMULA") ||
      userType === "Administrador"
    );
  };

  return (
    <Box
      transition="3s ease"
      bg={
        window.location.hostname === `pex-test.vercel.app` ? "red.400" : "white"
      }
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        {/* <Link to="/"> */}
        <Stack direction="row" mt="22px">
          <Flex align="center" gap="xl">
            <Image
              mt="65px"
              src={PexIcon}
              alt="LOGO"
              borderRadius="full"
              boxSize="155px"
              style={{ objectFit: "cover" }}
            />
          </Flex>
        </Stack>
        {/* </Link> */}
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Box mt="100px" fontSize="sm">
        {LinkItems.map((link) => {
          return shouldRenderLink(link, userType) ? (
            <Link
              key={link.name}
              to={link.path}
              style={{ textDecoration: "none" }}
              target={link.name === "Resultados PEX" ? "_blank" : ""}
            >
              <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                color="black"
                bg={isActive(link.path) ? "#FFC050" : "transparent"}
                border={isActive(link.path) ? "2px" : "transparent"}
                _hover={
                  window.location.hostname === `pex-test.vercel.app`
                    ? {
                        bg: "red",
                        color: "white",
                      }
                    : {
                        bg: "#FFC050",
                        color: "white",
                      }
                }
              >
                {link.name === "Resultados PEX" ? (
                  <Tooltip
                    label="Se lhe for pedido um email de acesso, entre em contato com o TI de sua empresa."
                    aria-label="A tooltip"
                  >
                    <Box>
                      {link.icon && (
                        <Icon
                          mr="4"
                          fontSize="16"
                          _groupHover={{
                            color: "white",
                          }}
                          as={link.icon}
                        />
                      )}
                      {link.name}
                    </Box>
                  </Tooltip>
                ) : link.name === "Manual PEX" ? (
                  <Box>
                    {link.icon && (
                      <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                          color: "white",
                        }}
                        as={link.icon}
                      />
                    )}
                    {link.name}
                  </Box>
                ) : (
                  <>
                    {link.icon && (
                      <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                          color: "white",
                        }}
                        as={link.icon}
                      />
                    )}
                    {link.name === "Sair" ? (
                      <Box>{link.name}</Box>
                    ) : (
                      <Box>{link.name}</Box>
                    )}
                  </>
                )}
              </Flex>
            </Link>
          ) : null;
        })}
      </Box>
    </Box>
  );
};

const Content = ({ onUserTypeChange }) => {
  const { userProfile } = useAppContext();
  const { empresaGlobal } = useAppContext();

  const ProtectedRoute = ({ element }) => {
    // Se o usuário não estiver autenticado, redireciona para a página de login
    if (!userProfile) {
      return <Navigate to="/" />;
    }

    return element;
  };

  return (
    <Routes>
      <Route
        path="/adicionar-item"
        element={<ProtectedRoute element={<AdicionarItemComponent />} />}
      />
      <Route
        path="/periodo-avaliacao"
        element={<ProtectedRoute element={<PeriodoAvaliacao />} />}
      />
      <Route
        path="/editar-remover-item"
        element={<ProtectedRoute element={<ExampleWithProviders />} />}
      />
      <Route
        path="/observacoes"
        element={<ProtectedRoute element={<SelecionarPeriodoObservacoes />} />}
      />
      <Route
        path="/observacoes/:ano/:trimestre"
        element={<ProtectedRoute element={<PontosPorItens />} />}
      />
      <Route
        path="/dashboard-avaliador"
        element={<ProtectedRoute element={<SelecionarPeriodoPontuacao />} />}
      />
      <Route
        path="/dashboard-avaliador/:ano/:trimestre"
        element={<ProtectedRoute element={<TabelaAvaliadoresTdsPontos />} />}
      />
      <Route
        path="/avaliador"
        element={<ProtectedRoute element={<SelecionarPeriodoAvaliador />} />}
      />
      <Route
        path="/avaliador/:ano/:trimestre"
        element={<ProtectedRoute element={<EscolhaDistribuidor />} />}
      />
      <Route
        path="/avaliador/:ano/:trimestre/:distribuidor"
        element={<ProtectedRoute element={<AvaliadorPage />} />}
      />
      <Route
        path="/evidencias"
        element={<ProtectedRoute element={<SelecionarPeriodoAnexo />} />}
      />
      <Route
        path="/evidencias/:ano/:trimestre"
        element={<ProtectedRoute element={<AnexarEvidencias />} />}
      />
      <Route path="/" element={<LoginPage />} />
      <Route path="*" element={<NotFound />} />
      <Route
        path="/alterar-senha"
        element={<ProtectedRoute element={<ForgotPassword />} />}
      />
      <Route path="/recuperar-senha" element={<RecoverPassword />} />
      <Route
        path="/manuais-pex"
        element={<ProtectedRoute element={<PaginaManual />} />}
      />
      <Route path="/selecionar-periodo" element={<SelecionarPeriodoAnexo />} />
      <Route
        path="/acompanhar-contestar"
        element={<ProtectedRoute element={<AcompanharContestar />} />}
      />
      {/* Redireciona para a página de login */}
    </Routes>
  );
};

const SidebarWithHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userType, setUserType] = useState("");
  const { user, setUser } = useAppContext();
  const { userProfile, setUserProfile } = useAppContext();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogoClick = () => {
    // Ao clicar na imagem, define o userProfile como uma string vazia
    setUserProfile("");
  };

  const handleLogout = () => {
    // Ao clicar em "Sair", define o userProfile como uma string vazia
    setUserProfile("");
    localStorage.removeItem("userProfile");
  };

  const handleUserTypeChange = (userProfile) => {
    setUserType(userProfile);
  };
  const { globalToken, setGlobalToken } = useAppContext();
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const { emailGlobal, setEmailGlobal } = useAppContext();
  const { passwordGlobal, setPasswordGlobal } = useAppContext();

  useEffect(() => {
    // Verificar se o emailGlobal não está em branco antes de executar o efeito
    if (emailGlobal.trim() !== "") {
      // Simulando uma operação assíncrona, por exemplo, uma chamada à API
      const fetchData = async () => {
        try {
          setIsLoadingTable(true);

          // Obter o token
          const tokenData = await getToken(emailGlobal, passwordGlobal);
          setGlobalToken(tokenData.access_token);
          // Obter os itens da API usando o token
          // const itemsData = await getAllItemsPex(tokenData.access_token);
          // const itemsDataPontos = await getAllItemsPexWithPoints(
          //   tokenData.access_token
          // );

          // setApiData(itemsData);
          // setApiDataPontos(itemsDataPontos);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          const storedProfile = userProfile;
          if (storedProfile) {
            setUserProfile(storedProfile);
            setIsLoggedIn(true);
          }
          setIsLoadingTable(false);
        }
      };

      const handleBeforeUnload = () => {
        // Limpar o localStorage ao sair da página
        localStorage.removeItem("userProfile");
      };

      fetchData();

      // Adicionar o manipulador de eventos beforeunload
      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        // Remover o manipulador de eventos beforeunload ao desmontar o componente
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [emailGlobal, passwordGlobal]);
  return (
    <Router>
      <Box minH="100vh" bg={isLoggedIn ? "white" : "gray.100"}>
        {isLoggedIn && (
          <SidebarContent
            onClose={onClose}
            display={{ base: "none", md: "block" }}
            userType={userProfile}
            onUserTypeChange={handleUserTypeChange}
            onLogout={handleLogout}
          />
        )}
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent
              onClose={onClose}
              userType={userProfile}
              onUserTypeChange={handleUserTypeChange}
            />
          </DrawerContent>
        </Drawer>
        <Box ml={{ base: 0, md: 60 }} p="4">
          <Content onUserTypeChange={handleUserTypeChange} />
        </Box>
      </Box>
    </Router>
  );
};

export default SidebarWithHeader;
